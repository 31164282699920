import {
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
  useRefresh,
  useQueryWithStore,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Divider from "@material-ui/core/Divider";
import CitiesSwitcher from "./CitiesSwitcher";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, useLocation } from "react-router-dom";

// Icons
import BallotIcon from "@material-ui/icons/Ballot";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import UserIcon from "@material-ui/icons/Face";
import CityIcon from "@material-ui/icons/LocationCity";
import LanguageIcon from "@material-ui/icons/Language";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RoomIcon from "@material-ui/icons/Room";
import EventIcon from "@material-ui/icons/Event";
import MapIcon from "@material-ui/icons/Map";
import BlockIcon from "@material-ui/icons/Block";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AvatarProfile from "./AvatarProfile";
import { ADMIN, EDITOR, SUPERADMIN } from "../lib/roles";

const MyMenu = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const useListStyles = makeStyles((theme) => ({
    root: {
      minWidth: "40px",
      color: "#707d8a",
    },
  }));

  const useTextStyles = makeStyles((themes) => ({
    root: {
      color: "#707d8a",
    },
  }));

  const useTextStylesOpen = makeStyles((themes) => ({
    root: {
      color: "#028FED",
    },
  }));

  const useCustomLink = makeStyles((themes) => ({
    root: {
      textDecoration: "none",
    },
  }));

  const { permissions } = usePermissions();

  const initialState = {
    cities: false,
    news: false,
    mobility: false,
    events: false,
    courses: false,
  };
  const [open, setOpen] = React.useState(initialState);
  const classes = useStyles();
  const listClasses = useListStyles();
  const textClasses = useTextStyles();
  const textClassesOpen = useTextStylesOpen();
  const customLink = useCustomLink();
  const refresh = useRefresh();
  const location = useLocation();

  const handleClick = (feature) => {
    const newState = { ...initialState };
    newState[feature] = !open[feature];
    setOpen(newState);
  };

  const handleClickPois = (type) => {
    sessionStorage.setItem("poi_type", type);
    refresh();
  };

  const closeDropDowns = () => {
    setOpen(initialState);
  };

  const { data } = useQueryWithStore({
    type: "GET_ONE",
    resource: "cities",
    payload: {
      id: localStorage.getItem("city_id"),
    },
  });

  const { data: features } = useQueryWithStore({
    type: "GET_MANY",
    resource: "cityFeatures",
    payload: {
      ids: data ? data.features_ids : [],
    },
  });

  let features_slugs = [];
  if (features) {
    features_slugs = features.map((value) => value.slug);
  }

  if (features) {
    return (
      <div>
        <AvatarProfile />
        <Divider
          style={{
            margin: "10px 0px",
          }}
        />
        <CitiesSwitcher onChange={closeDropDowns} />
        {permissions && permissions.includes(EDITOR) && (
          <DashboardMenuItem onClick={closeDropDowns} />
        )}
        {features_slugs.includes("noticias") && (
          <MenuItemLink
            key="articles"
            to="/articles"
            primaryText="Notícias"
            leftIcon={<MenuBookIcon />}
          />
        )}
        {features_slugs.includes("eventos") && (
          <MenuItemLink
            key="events"
            to="/events"
            primaryText="Eventos"
            leftIcon={<EventIcon />}
          />
        )}
        {features_slugs.includes("pois") && (
          <>
            <ListItem button onClick={() => handleClick("pois")}>
              <ListItemIcon classes={listClasses}>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText
                primary="Pontos de interesse"
                classes={open.pois ? textClassesOpen : textClasses}
              />
              {open.pois ? (
                <ExpandLess classes={textClassesOpen} />
              ) : (
                <ExpandMore classes={textClasses} />
              )}
            </ListItem>
            <Collapse in={open.pois} timeout="auto" unmountOnExit>
              <List component="div">
                <MenuItemLink
                  className={classes.nested}
                  key="pois"
                  to="/pois"
                  onClick={() => {
                    handleClickPois("pois");
                  }}
                  primaryText="Pontos de interesse"
                />
                <MenuItemLink
                  className={classes.nested}
                  key="importPoi"
                  to="/importPoi"
                  primaryText="Importar ponto de interesse"
                />
                <MenuItemLink
                  className={classes.nested}
                  key="bulkPoi"
                  to="/bulkPoi"
                  primaryText="Importar varios"
                />
              </List>
            </Collapse>
          </>
        )}
        {features_slugs.includes("percursos") && (
          <MenuItemLink
            key="courses"
            to="/courses"
            primaryText="Percursos"
            leftIcon={<MapIcon />}
          />
        )}
        <ListItem button onClick={() => handleClick("mobility")}>
          <ListItemIcon classes={listClasses}>
            <DepartureBoardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Mobilidade"
            classes={open.mobility ? textClassesOpen : textClasses}
          />
          {open.mobility ? (
            <ExpandLess classes={textClassesOpen} />
          ) : (
            <ExpandMore classes={textClasses} />
          )}
        </ListItem>
        <Collapse in={open.mobility} timeout="auto" unmountOnExit>
          <List component="div">
            {features_slugs.includes("autocarros") && (
              <MenuItemLink
                className={classes.nested}
                key="transportes"
                to="/transportes"
                primaryText="Transportes"
              />
            )}
            {features_slugs.includes("taxis") && (
              <Link to="/pois" className={customLink.root}>
                <MenuItem
                  className={classes.nested}
                  onClick={() => {
                    handleClickPois("taxis");
                  }}
                  key="taxis"
                  selected={
                    sessionStorage.getItem("poi_type") === "taxis" &&
                    location.pathname.startsWith("/pois")
                  }
                >
                  Taxis
                </MenuItem>
              </Link>
            )}
            {features_slugs.includes("carregamentos_eletricos") && (
              <Link to="/pois" className={customLink.root}>
                <MenuItem
                  className={classes.nested}
                  onClick={() => {
                    handleClickPois("eletric");
                  }}
                  key="carregamentos"
                  selected={
                    sessionStorage.getItem("poi_type") === "eletric" &&
                    location.pathname.startsWith("/pois")
                  }
                >
                  Carregamento Elétricos
                </MenuItem>
              </Link>
            )}
            {features_slugs.includes("parques_estacionamento") && (
              <Link to="/pois" className={customLink.root}>
                <MenuItem
                  className={classes.nested}
                  onClick={() => {
                    handleClickPois("park");
                  }}
                  key="parques"
                  selected={
                    sessionStorage.getItem("poi_type") === "park" &&
                    location.pathname.startsWith("/pois")
                  }
                >
                  Parques estacionamento
                </MenuItem>
              </Link>
            )}
          </List>
        </Collapse>
        {features_slugs.includes("ocorrencias") && (
          <MenuItemLink
            onClick={closeDropDowns}
            key="incidents"
            to="/incidents"
            primaryText="Ocorrencias"
            leftIcon={<BlockIcon />}
          />
        )}
        <MenuItemLink
          key="notifications"
          to="/notifications"
          primaryText="Notificações"
          leftIcon={<EventIcon />}
        />
        {permissions && permissions.includes(ADMIN) && (
          <MenuItemLink
            key="sinaletica"
            to="/signposting"
            leftIcon={<RoomIcon />}
            primaryText="Pontos de sinalética"
          />
        )}
        <Divider
          style={{
            margin: "10px 0px",
          }}
        />
        <h3
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Administração
        </h3>
        {permissions && permissions.includes(ADMIN) && (
          <MenuItemLink
            onClick={closeDropDowns}
            key="users"
            to="/users"
            primaryText="Utilizadores"
            leftIcon={<UserIcon />}
          />
        )}
        {permissions &&
          permissions.includes(ADMIN) &&
          !permissions.includes(SUPERADMIN) && (
            <MenuItemLink
              key="cities"
              to="/cities"
              primaryText="Cidades"
              leftIcon={<CityIcon />}
            />
          )}
        {permissions && permissions.includes(SUPERADMIN) && (
          <>
            <ListItem button onClick={() => handleClick("cities")}>
              <ListItemIcon classes={listClasses}>
                <CityIcon />
              </ListItemIcon>
              <ListItemText
                primary="Cidades"
                classes={open.cities ? textClassesOpen : textClasses}
              />
              {open.cities ? (
                <ExpandLess classes={textClassesOpen} />
              ) : (
                <ExpandMore classes={textClasses} />
              )}
            </ListItem>
            <Collapse in={open.cities} timeout="auto" unmountOnExit>
              <List component="div">
                <MenuItemLink
                  className={classes.nested}
                  key="cities"
                  to="/cities"
                  primaryText="Todas as cidades"
                />
                <MenuItemLink
                  className={classes.nested}
                  key="cityFeatures"
                  to="/cityFeatures"
                  primaryText="Funcionalidades"
                />
              </List>
            </Collapse>
          </>
        )}
        {permissions && permissions.includes(ADMIN) && (
          <>
            <MenuItemLink
              onClick={closeDropDowns}
              key="pages"
              to="/pages"
              primaryText="Páginas"
              leftIcon={<LibraryBooksIcon />}
            />
            <MenuItemLink
              onClick={closeDropDowns}
              key="categories"
              to={{
                pathname: `/categories`,
                search: `filter=${JSON.stringify({ parent_id: undefined })}`,
              }}
              primaryText="Categorias"
              leftIcon={<BallotIcon />}
            />
            <MenuItemLink
              onClick={closeDropDowns}
              key="tags"
              to="/tags"
              primaryText="Tags"
              leftIcon={<LocalOfferIcon />}
            />
            <MenuItemLink
              onClick={closeDropDowns}
              key="languages"
              to="/languages"
              primaryText="Línguas"
              leftIcon={<LanguageIcon />}
            />
          </>
        )}
      </div>
    );
  }
  return null;
};

export default MyMenu;
