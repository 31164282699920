import React from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

const MyUserMenu = props => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    return (
      <UserMenu label="My Profile" {...props}>
        <MenuItemLink
          to={`/users/${user.id}`}
          primaryText="My profile"
          leftIcon={<SettingsIcon />}
        />
      </UserMenu>
    );
  }
  return null;
};

export default MyUserMenu;