import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useRecordContext, TextInput as TI } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';
import { useForm } from "react-final-form";
import TextField from '@material-ui/core/TextField';

const GoogleMapInput = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const form = useForm();
    let serverLat = 0;
    let serverLng = 0;
    if (record[source]) {
        serverLat = record[source].coordinates[0];
        serverLng = record[source].coordinates[1];
    }

    const [lat, setLat] = useState(serverLat);
    const [lng, setLng] = useState(serverLng);
    const [center, setCenter] = useState({ lat: serverLat, lng: serverLng });
    const searchBoxRef = useRef(null);
    const latRef = useRef(null);
    const lngRef = useRef(null);
    let searchBox = null;

    const onPlacesChanged = (e) => {
        const place = searchBox.getPlaces()[0];
        latRef.current.value = place.geometry.location.lat();
        lngRef.current.value = place.geometry.location.lng();
        searchBoxRef.current.value = place.name;
        setLat(place.geometry.location.lat());
        setLng(place.geometry.location.lng());
        setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        form.change(source, { type: "Point", coordinates: [place.geometry.location.lat(), place.geometry.location.lng()] });
    }

    const google = window.google;
    const input = ReactDOM.findDOMNode(searchBoxRef.current);
    searchBox = new google.maps.places.SearchBox(input);

    useEffect(() => {
        // Update the document title using the browser API
        searchBox.addListener('places_changed', onPlacesChanged);
    });

    return (
        <>
            <div style={{ height: '400px', width: '100%' }}>
                <GoogleMapReact
                    {...props}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_API_KEY, libraries: ['places'], }}
                    center={center}
                    defaultZoom={props.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => { }}
                >
                    <div style={{
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <RoomIcon
                            style={{ color: "red" }}
                            lat={lat}
                            lng={lng}
                        />
                    </div>
                </GoogleMapReact>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TI
                        fullWidth
                        label="Procurar"
                        name="place"
                        inputRef={searchBoxRef}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label="Latitude"
                        type="number"
                        name="lat"
                        required
                        defaultValue={lat}
                        inputRef={latRef}
                        onChange={(e) => {
                            let val = 0;
                            if (e.target.value && !isNaN(e.target.value)) {
                                val = e.target.value;
                            }
                            setLat(parseFloat(val));
                            setCenter({ ...center, lat: parseFloat(val) })
                            form.change(source, { type: "Point", coordinates: [parseFloat(val), lng] });
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label="Longitude"
                        type="number"
                        defaultValue={lng}
                        name="lng"
                        required
                        inputRef={lngRef}
                        onChange={(e) => {
                            let val = 0;
                            if (e.target.value && !isNaN(e.target.value)) {
                                val = e.target.value;
                            }
                            setLng(parseFloat(val))
                            setCenter({ ...center, lng: parseFloat(val) })
                            form.change(source, { type: "Point", coordinates: [lat, parseFloat(val)] });
                        }}
                    />
                </Grid>
            </Grid>
            <TI
                variant="standard"
                margin="none"
                label=""
                type="hidden"
                source={source}
                parse={inputValue => JSON.parse(inputValue)}
                format={formValue => JSON.stringify(formValue)}
            />
        </>
    )
}

GoogleMapInput.propTypes = {
    zoom: PropTypes.object,
    source: PropTypes.string.isRequired,
};

GoogleMapInput.defaultProps = {
    zoom: 11,
};

export default GoogleMapInput;