import React from "react";
import { TextInput, Create, SimpleForm, useNotify, TranslatableInputs } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import { ColorInput } from 'react-admin-color-input';
import { locales } from "../../lib/utils";


const TagCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create tag: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Noca tag" onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="name" validate={validateRequired} title="Nome" />
                </TranslatableInputs>
                <ColorInput source="color" label="Cor" pickker="Sketch" validate={validateRequired} />
            </SimpleForm>
        </Create>
    )
};

export default TagCreate;