import React from 'react';
import { client } from "../../lib/customDataProvider";
import gql from "graphql-tag";
import { Query } from 'react-apollo';
import { Error, LinearProgress, Title } from 'react-admin';
import { Link } from "react-router-dom";
import { Paper } from '@material-ui/core';


const GET_AGENCIES = gql`
    query Query {
    allAgencies {
        agency_id
        agency_name
        agency_url
        agency_timezone
        agency_lang
        agency_phone
        agency_fare_url
        agency_email
    }
}
`;


const Agencies = props => {
    return (
        <Paper elevation={2} style={{ marginTop: 20, padding: 20 }}>
            <Title title={"Agencias"} />
            <Query query={GET_AGENCIES} client={client} fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                    if (loading) return <LinearProgress />;
                    if (error) return <Error />;

                    return data.allAgencies.map((agency) => {
                        return <div><Link
                            to={`/transportes/routes/${agency.agency_id}`}
                        >{`${agency.agency_id} - ${agency.agency_name}`}</Link></div>
                    })

                }}
            </Query>
        </Paper>
    );
};

export { Agencies };

const GET_ROUTES = gql`
    query Query($agencyId: String!) {
    allRoutes(agency: $agencyId) {
        route_id
        route_short_name
        route_long_name
        route_type
    }
}
`;


const Tub = props => {
    return (
        <Paper elevation={2} style={{ marginTop: 20, padding: 20 }}>
            <Title title={"Linhas"} />
            <Query query={GET_ROUTES} variables={{ agencyId: props.match.params.id }} client={client} fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                    if (loading) return <LinearProgress />;
                    if (error) return <Error />;

                    return data.allRoutes.map((route) => {
                        return <div><Link
                            to={`/transportes/route/${route.route_id}`}
                        >{`${route.route_id} - ${route.route_long_name}`}</Link></div>
                    })

                }}
            </Query>
        </Paper>
    );
};


export { Tub };

const GET_TRIPS = gql`
    query Query($routeRouteId: String!) {
        Route(route_id: $routeRouteId) {
            route_id
            trips {
                trip_id
                trip_headsign
                direction_id
                shape_id
                stops {
                    departure_time
                    arrival_time
                    stop {
                        stop_name
                    }
                }
            }
        }
    }
`;

const Trips = props => {
    return (
        <Paper elevation={2} style={{ marginTop: 20, padding: 20 }}>
            <Title title={"Viagens"} />
            <Query query={GET_TRIPS} variables={{ routeRouteId: props.match.params.id }} client={client} fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                    if (loading) return <LinearProgress />;
                    if (error) return <Error />;
                    return data.Route.trips.map((trip) => {
                        return <div><Link
                            to={`/transportes/trip/${trip.trip_id}`}
                        >{`${trip.stops[0].departure_time}: ${trip.stops[0].stop.stop_name} - ${trip.stops[trip.stops.length - 1].departure_time}: ${trip.stops[trip.stops.length - 1].stop.stop_name}`}</Link></div>
                    })

                }}
            </Query>
        </Paper>
    );
}

export { Trips };

const GET_STOPS = gql`
    query Query($tripTripId: String!) {
        Trip(trip_id: $tripTripId) {
            trip_id
            stops {
                arrival_time
                departure_time
                stop {
                    stop_name
                    zone_id
                }
            }
        }
    }
`;

const Trip = props => {
    return (
        <Paper elevation={2} style={{ marginTop: 20, padding: 20 }}>
            <Title title={"Paragens"} />
            <Query query={GET_STOPS} variables={{ tripTripId: props.match.params.id }} client={client} fetchPolicy='network-only'>
                {({ loading, error, data }) => {
                    if (loading) return <LinearProgress />;
                    if (error) return <Error />;
                    return data.Trip.stops.map((stop) => {
                        return <div>{`${stop.arrival_time} - ${stop.stop.stop_name} --> ${stop.stop.zone_id}`}</div>
                    })

                }}
            </Query>
        </Paper>
    );
}

export { Trip };
