import * as React from "react";
import { useRecordContext, useQuery, LinearProgress, useLocale } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useCustomLink = makeStyles(themes => ({
    root: {
        textDecoration: "none",
        color: "#028FED"
    }
}));

const CategoryCount = (props) => {
    const record = useRecordContext(props);
    const customLink = useCustomLink();
    const locale = useLocale();

    const { total, loading } = useQuery({
        type: 'GET_LIST',
        resource: props.resource,
        payload: {
            filter: {
                parent_id: record.id,
            },
            pagination: {
                page: 1,
                perPage: 1,
            },
            sort: {
                sortField: 'createdAt',
                sortOrder: 'DESC',
            },
        },
    });

    if (loading) {
        return <LinearProgress />
    }

    return (
        <div>
            {total > 0 && (
                <Link
                    to={{
                        pathname: `/${props.resource}`,
                        search: `filter=${JSON.stringify({ parent_id: record.id })}&parent_name=${record.name[locale]}`,
                    }}
                    className={customLink.root}
                >
                    <Button className={customLink.root}>Ver Sub Categorias ({total})</Button>
                </Link>
            )}
        </div>
    )
}

export default CategoryCount;