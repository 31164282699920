import React from 'react';

const Loading = () => (
    <svg
        style={{
            position: 'absolute',
            top: 'calc(50% - 100px)',
            left: 'calc(50% - 100px)',
            display: 'block',
            shapeRendering: 'auto',
        }}
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        width="100px"
        height="100px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
        <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#95C01F"
            strokeWidth="10"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138"
            transform="rotate(209.891 50 50)"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
            ></animateTransform>
        </circle>
    </svg>
);

export default Loading;