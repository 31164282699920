import * as React from "react";
import { Show, SimpleShowLayout, TextField, TranslatableFields, SelectField, BooleanField, ImageField, DateField, useLocale } from 'react-admin';
import { locales } from "../../lib/utils";

const NotificationTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? record.title[locale] : ''}</span>;
};

const NotificationShow = (props) => {
    let types = [
        { id: 'ALERT', name: 'Alerta' },
        { id: 'NOTIFICATION', name: 'Notificação' },
    ]
    return (
        <Show {...props} title={<NotificationTitle />}>
            <SimpleShowLayout>
                <SelectField source="type" choices={types} />
                <TranslatableFields locales={locales}>
                    <TextField source={`title`} label="Título" />
                    <TextField source="description" label="Descrição" />
                    <BooleanField source="send" label="Enviar nesta lingua" />
                </TranslatableFields>
                <ImageField source="image.src" title="title" label="Imagem" />
                <DateField source="finalDate" label="Data final" />
            </SimpleShowLayout>
        </Show>
    )
};

export default NotificationShow;