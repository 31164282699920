import React from "react";
import { TextInput, Create, TabbedForm, FormTab, useNotify, BooleanInput, useLocale, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, ReferenceInput, AutocompleteInput, ArrayInput, SimpleFormIterator, NumberInput, FormDataConsumer, TranslatableInputs } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import RichTextInput from 'ra-input-rich-text';
import { toolbarOptions } from "../../lib/editorOptions";
import { locales } from '../../lib/utils';

const CourseCreate = (props) => {
    const notify = useNotify();
    const locale = useLocale();

    const onFailure = (error) => {
        notify(`Could not create Course: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Novo percurso" onFailure={onFailure} {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="Conteúdo">
                    <TranslatableInputs locales={locales}>
                        <TextInput source="title" validate={validateRequired} label="Título" />
                        <RichTextInput source="content" validate={validateRequired} toolbar={toolbarOptions} label="Conteúdo" />
                        <BooleanInput source="published" label="Publicado" />
                    </TranslatableInputs>
                    <ReferenceArrayInput source="categories_ids" reference="categories" label="Categorias" filter={{ parent_id: 'dropdown' }}>
                        <SelectArrayInput optionText={`name[${locale}]`} />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="tags_ids" reference="tags">
                        <SelectArrayInput optionText={`name[${locale}]`} />
                    </ReferenceArrayInput>
                </FormTab>
                <FormTab label="Imagens">
                    <ImageInput source="image" label="Imagem de capa" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <ImageInput source="gallery" label="Galeria" accept="image/*" multiple>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Percurso">
                    <ArrayInput source="pois_ids" label="Ponto de interesse">
                        <SimpleFormIterator>
                            <ReferenceInput reference="pois" label="Título">
                                <AutocompleteInput optionText={`title[${locale}]`} />
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Preço">
                    <BooleanInput source="price[free]" label="Gratuito" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return ((!formData.price || !formData.price.free) && <NumberInput source="price[value]" {...rest} label="Preço" />)
                        }
                        }
                    </FormDataConsumer>
                    <TranslatableInputs locales={locales}>
                        <TextInput source={`price[info]`} label="Mais informações" />
                    </TranslatableInputs>
                </FormTab>
                <FormTab label="Informação percurso">
                    <BooleanInput source="info[signs]" label="Sinais" />
                    <NumberInput source="info[distance]" label="Distância" />
                    <TextInput type="time" source="info[time]" label="Duração" />
                    <TranslatableInputs locales={locales}>
                        <TextInput source="info[difficulty]" label="Dificulade" />
                    </TranslatableInputs>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default CourseCreate;