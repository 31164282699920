import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { TextInput, Edit, SimpleForm, TranslatableInputs, useLocale } from 'react-admin';
import { toolbarOptions } from "../../lib/editorOptions";
import { locales } from "../../lib/utils";
import { validateRequired } from "../../lib/validations";

const PageTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? record.title[locale] : ''}</span>;
};


const PageEdit = (props) => {
    return (
        <Edit title={<PageTitle />} {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="id" disabled />
                <TranslatableInputs locales={locales}>
                    <TextInput source="title" validate={validateRequired} label="Título" />
                    <RichTextInput source="content" toolbar={toolbarOptions} label="Conteúdo" />
                </TranslatableInputs>
            </SimpleForm>
        </Edit>
    )
};

export default PageEdit;