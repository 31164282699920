import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import red from '@material-ui/core/colors/red';

const myCityTheme = merge({}, defaultTheme, {
    palette: {
        primary: { main: "#028FED" },
        secondary: { main: "#25C2AC" },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "#343a40"
        }
    },
    sidebar: {
        width: 240, // The default value is 240
        closedWidth: 240, // The default value is 55
    },
    overrides: {
        RaLayout: {
            root: {
                backgroundColor: '#f3f5f7',
                position: 'relative',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    width: '100%',
                    height: '165px',
                    background: 'linear-gradient(to right, #028FED, #0180D5)',
                    boxShadow: '1px 0 7px 0 rgb(0 0 0 / 50%)',
                    zIndex: '-1',
                },
                '&:after': {
                    background: 'url(/images/bg-effect.png) center',
                    content: '""',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    width: '100%',
                    height: '180px',
                    backgroundSize: 'cover',
                    opacity: '.03',
                    zIndex: '-1',
                }
            },
            appFrame: {
                marginTop: '100px',
                '@media (min-width: 0px)': {
                    marginTop: '100px',
                }
            },
        },
        RaFormInput: {
            input: {
                width: '100%',
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            }
        },
        MuiAppBar: {
            root: {
                height: '70px'
            },
            colorSecondary: {
                backgroundColor: '#028FED',
            },
        },
        RaTranslatableInputsTabs: {
            root: {
                height: 'auto',
            }
        },
        MuiToolbar: {
            dense: {
                height: '70px',
            },
        },
        RaMenuItemLink: {
            root: {
                color: "#707d8a",
            },
            active: {
                color: "#028FED",
            },
        },
        MuiDrawer: {
            root: {
                margin: '0 15px',
                boxShadow: '0 0.75rem 1.5rem rgb(18 38 63 / 3%)',
                backgroundColor: '#fff',
                borderTopLeftRadius: '7px',
                borderTopRightRadius: '7px',
                paddingTop: '15px',
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0 0.75rem 1.5rem rgb(18 38 63 / 3%)',
                borderRadius: '0.25rem',
            }
        },
        RaAutocompleteSuggestionList: {
            suggestionsPaper: {
                boxShadow: '0 0.75rem 1.5rem rgb(18 38 63 / 50%)',
            },
        },
        RaAutocompleteArrayInput: {
            inputRoot: {
                maxWidth: '500px',
            }
        },
        MuiListItem: {
            root: {
                flex: 1,
                color: "#707d8a",
                textDecoration: 'none',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                '&.Mui-selected': {
                    backgroundColor: 'transparent',
                    color: '#028FED',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }
                }
            }
        },
        RaSidebar: {
            root: {
                height: 'auto',
            },
            fixed: {
                position: 'relative',
                height: '100%',
            }
        },
        RaAppBar: {
            menuButton: {
                '@media only screen and (min-width: 768px)': {
                    visibility: 'hidden',
                    marginLeft: '-20px',
                }
            }
        }
    },
});

export default myCityTheme;