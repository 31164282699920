import * as React from "react";
import {
  TextInput,
  Edit,
  TabbedForm,
  FormTab,
  PasswordInput,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
  useLocale,
} from "react-admin";
import { ADMIN, EDITOR, SUPERADMIN, USER, CITYADMIN } from "../../lib/roles";
import { validateRequired } from "../../lib/validations";

const UserTitle = ({ record }) => {
  return <span>{record ? record.name : ""}</span>;
};

const UserEdit = (props) => {
  const locale = useLocale();
  let roles = [
    { id: USER, name: "User" },
    { id: EDITOR, name: "Editor" },
    { id: CITYADMIN, name: "City Admin" },
    { id: ADMIN, name: "Admin" },
  ];

  if (props.permissions && props.permissions.includes(SUPERADMIN)) {
    roles.push({ id: SUPERADMIN, name: "Super Admin" });
  }

  return (
    <Edit title={<UserTitle />} {...props}>
      <TabbedForm redirect="edit">
        <FormTab label="summary">
          <TextInput disabled source="id" />
          <ImageInput source="avatar" label="Avatar   " accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="name" validate={validateRequired} />
          <ReferenceArrayInput source="cities_ids" reference="cities">
            <SelectArrayInput optionText={`name[${locale}]`} />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Privacy">
          <PasswordInput source="password" />
          <TextInput disabled source="email" validate={validateRequired} />
          <SelectArrayInput
            source="role"
            choices={roles}
            validate={validateRequired}
          />
          <ReferenceInput
            label="Language"
            source="language_id"
            reference="languages"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default UserEdit;
