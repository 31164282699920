import * as React from "react";
import { TextInput, Edit, SimpleForm, ImageInput, ImageField, useLocale, TranslatableInputs, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';
import { locales } from "../../lib/utils";
import { validateRequired } from "../../lib/validations";

const CategoryTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? record.name[locale] : ''}</span>;
};


const CategoryEdit = (props) => {
    const locale = useLocale();
    return (
        <Edit title={<CategoryTitle />} {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="id" disabled />
                <TranslatableInputs locales={locales}>
                    <TextInput source="name" validate={validateRequired} label="Nome" />
                </TranslatableInputs>
                <ReferenceInput source="parent_id" reference="categories" label="Categoria pai" allowEmpty perPage={1000} filter={{ parent_id: 'dropdown' }}>
                    <SelectInput optionText={`name.${locale}`} />
                </ReferenceInput>
                <BooleanInput label="Popup Layout" source="popup_layout" />
                <ImageInput source="image" label="Imagem de capa" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="icon" label="Icon" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="pin_image" label="Pin Image" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
};

export default CategoryEdit;