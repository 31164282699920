import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';

const CityFeatureFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const CityList = (props) => {
    return (
        <List title="Funcionalidades de cidades" {...props} filters={<CityFeatureFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="name" label="Nome" />
                <TextField source="slug" />
                <TextField source="description" label="Descrição" />
            </Datagrid>
        </List>
    )
};

export default CityList;