class Errors {
    static handleError( error ) {
        switch (error) {
            case 'GraphQL error: UserAlreadyExist':
                return this.userAlreadyExist();
            default:
                return error;
        }
    }

    static userAlreadyExist() {
        return 'User already exist';
    }

    static generalError() {
        return 'Contact admin';
    }
}

export default Errors;