import React from "react";
import { TextInput, Create, SimpleForm, useNotify, ImageInput, ImageField, TranslatableInputs, ReferenceInput, SelectInput, useLocale, BooleanInput } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import { locales } from '../../lib/utils';

const CategoryCreate = (props) => {
    const locale = useLocale();
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create city: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Nova categoria" onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="name" validate={validateRequired} label="Nome" />
                </TranslatableInputs>
                <ReferenceInput source="parent_id" reference="categories" label="Categoria pai" allowEmpty perPage={1000} filter={{ parent_id: 'dropdown' }}>
                    <SelectInput optionText={`name.${locale}`} />
                </ReferenceInput>
                <BooleanInput label="Popup Layout" source="popup_layout" />
                <ImageInput source="image" label="Imagem de capa" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="icon" label="Icon" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="pin_image" label="Pin Image" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
};

export default CategoryCreate;