import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        fontSize: '13px',
        margin: '5px 0'
    },
});

const DescriptionField = (props) => {
    const classes = useStyles();
    return <div className={classes.root}>{props.content}</div>
}

DescriptionField.propTypes = {
    content: PropTypes.string.isRequired,
};

export default DescriptionField;