import React from "react";
import { TextInput, Create, SimpleForm, useNotify, TranslatableInputs } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import RichTextInput from 'ra-input-rich-text';
import { toolbarOptions } from "../../lib/editorOptions";
import { locales } from "../../lib/utils";

const PageCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create Page: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Nova página" onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="title" label="Título" validate={validateRequired} />
                    <RichTextInput source="content" toolbar={toolbarOptions} validate={validateRequired} label="Conteúdo" />
                </TranslatableInputs>
            </SimpleForm>
        </Create>
    )
};

export default PageCreate;