import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import { TextField, Paper, Button } from '@material-ui/core';
import { useMutation } from 'react-admin';


const PoiSearch = props => {
    const searchBoxRefGS = useRef(null);
    const [info, setInfo] = useState({});
    const [disabledButton, setDisabledButton] = useState(true);
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState("");
    const [mutate, { loading }] = useMutation();
    let searchBox = null;

    const onPlacesChanged = (e) => {
        const place = searchBox.getPlaces()[0];
        setInfo(place);
        searchBoxRefGS.current.value = place.name;
        setDisabledButton(false);
        setSuccess(false);
        setName("");
    }

    const insert = async event => mutate({
        type: 'create',
        resource: 'pois',
        payload: {
            data: {
                title: {
                    pt: info.name,
                    en: info.name,
                    es: info.name,
                    fr: info.name,
                },
                published: {
                    pt: true,
                    en: true,
                    es: true,
                    fr: true,
                },
                rate: info.rating,
                google_gallery: info.photos ? info.photos.map(photo => { return {src: photo.getUrl({ maxWidth: 500 })} }) : [],
                contacts: {
                    phone: [info.international_phone_number],
                    url: [info.website],
                    email: [],
                },
                location: {
                    type: "Point",
                    coordinates: [info.geometry.location.lat(), info.geometry.location.lng()]
                },
                schedule: event,
                address: info.formatted_address,
                google_place_id: info.place_id,
            }
        },
    });

    const handleImport = async e => {
        setSuccess(false);
        setDisabledButton(!loading);
        const schedule = {};
        if (info.opening_hours) {
            for (let i = 0; i < info.opening_hours.weekday_text.length; i++) {
                const element = info.opening_hours.weekday_text[i];
                let day = {};
                const time = element.split(": ")[1];
                if (time === "Fechado" || time === "Atendimento 24 horas") {
                    if (time === "Fechado") {
                        day.open = false;
                    } else {
                        day.open = true;
                    }
                    if (time === "Atendimento 24 horas") {
                        day.allday = true;
                    } else {
                        day.allday = false;
                    }
                } else {
                    day.open = true;
                    day.allday = false;
                    const times = time.split(", ");
                    day.times = times.map(t => {
                        return {
                            start_time: t.split(" – ")[0],
                            end_time: t.split(" – ")[1],
                        }
                    });
                }
                switch (i) {
                    case 0:
                        schedule.monday = day;
                        break;
                    case 1:
                        schedule.tuesday = day;
                        break;
                    case 2:
                        schedule.wednesday = day;
                        break;
                    case 3:
                        schedule.thursday = day;
                        break;
                    case 4:
                        schedule.friday = day;
                        break;
                    case 5:
                        schedule.saturday = day;
                        break;
                    case 6:
                        schedule.sunday = day;
                        break;
                    default:
                        break;
                }
            }
        }
        await insert(schedule);
        setName(info.name);
        setSuccess(true);
        searchBoxRefGS.current.value = "";
        setInfo({});
    }

    /* eslint-disable */
    useEffect(() => {
        const google = window.google;
        const input = ReactDOM.findDOMNode(searchBoxRefGS.current);
        searchBox = new google.maps.places.SearchBox(input);
        // Update the document title using the browser API
        searchBox.addListener('places_changed', onPlacesChanged);
    });

    console.log(info);

    return (
        <>
            <TextField
                fullWidth
                label="Procurar"
                name="placeGS"
                inputRef={searchBoxRefGS}
                variant="filled"
            />
            <Button variant="outlined" style={{ marginTop: 5 }} disabled={disabledButton} onClick={handleImport}>Importar</Button>
            {success && (<div><small><b>{name}</b> Importado com sucesso</small></div>)}
            <Paper elevation={2} style={{ marginTop: 20, padding: 20 }}>
                <div>ID: {info.place_id}</div>
                <div>Nome: {info.name}</div>
                <div>Rating: {info.rating}</div>
                {info.photos && info.photos.map((photo) => {
                    return (
                        <div>
                            <img src={photo.getUrl({ maxWidth: 500 })} alt="" />
                        </div>
                    )
                })}
                <div>Tel: {info.international_phone_number}</div>
                <div>URL: {info.website}</div>
                <div>Endereço: {info.formatted_address}</div>
                <div>Horários: </div>
                {info.opening_hours && info.opening_hours.weekday_text.map(text => {
                    return <div>{text}</div>
                })}
                <div>Localização: {info.geometry && info.geometry.location.lat()}, {info.geometry && info.geometry.location.lng()}</div>
            </Paper>
        </>
    )
}

export default PoiSearch;