import {
    required,
    email,
} from 'react-admin';

const validateRequired = required();
const validateEmail = [required(), email()];


export {
    validateRequired,
    validateEmail,
}