import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

const GoogleMapField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const center = {
        lat: record[source].coordinates[0],
        lng: record[source].coordinates[1]
    }
    return (
        <div style={{ height: '400px', width: '100%' }}>
            <GoogleMapReact
                {...props}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_API_KEY }}
                defaultCenter={center}
                defaultZoom={props.zoom}
            >
                <RoomIcon
                    style={{ color: "red" }}
                    lat={record[source].coordinates[0]}
                    lng={record[source].coordinates[1]}
                />
            </GoogleMapReact>
        </div>
    )
}

GoogleMapField.propTypes = {
    zoom: PropTypes.number,
    source: PropTypes.string.isRequired,
};

GoogleMapField.defaultProps = {
    zoom: 15,
    options: {}
};

export default GoogleMapField;