import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, useLocale, ImageField, EditButton } from 'react-admin';
import CategoryCount from "../../components/CategoryCount";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        image: {
            borderRadius: '5px',
            width: 'auto',
            maxHeight: '40px',
        }
    }));

const CategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);


export const CategoryList = (props) => {
    const locale = useLocale();
    const classes = useStyles();

    const parent_name = new URLSearchParams(props.location.search).get('parent_name');

    return (
        <List title={parent_name ? `Sub categorias de ${parent_name}` : "Categorias"} {...props} filters={<CategoryFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick={false}>
                <ImageField source="icon[src]" label="Icon" classes={classes} />
                <TextField source={`name[${locale}]`} label="Nome" />
                <CategoryCount label="Sub categorias" />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export default CategoryList;