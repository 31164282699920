import React, { useState, useEffect } from "react";
import { useRecordContext } from 'react-admin';
import { useChoices } from 'ra-core';
import CheckboxTree from 'react-checkbox-tree';
import { useForm } from "react-final-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

library.add(fab);
library.add(far);
library.add(fas);


/* const compareArray = (arr1, arr2) => {
    const array2Sorted = arr2.slice().sort();
    return arr1.length === arr2.length && arr1.slice().sort().every(function (value, index) {
        return value === array2Sorted[index];
    });
} */

const createDataTree = dataset => {
    const hashTable = Object.create(null);
    dataset.forEach(aData => hashTable[aData.id] = { ...aData, children: [] });
    const dataTree = [];
    dataset.forEach(aData => {
        if (aData.parent_id) {
            hashTable[aData.parent_id].children.push(hashTable[aData.id]);
        } else {
            dataTree.push(hashTable[aData.id]);
        }
    });
    return dataTree;
};

const removeEmpty = nodes => {

    const n = [];
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].children.length === 0) {
            n.push({
                ...nodes[i],
                children: null,
            });
        } else {
            n.push({
                ...nodes[i],
                children: removeEmpty(nodes[i].children)
            });
        }
    }
    return n;
}


const CategoriesTree = (props) => {
    const { source, optionText, choices } = props;
    const record = useRecordContext(props);
    const form = useForm();
    const [checkedF, setChecked] = useState(record[source]);
    const [expandedF, setExpanded] = useState();
    const [nodes, setNodes] = useState([]);
    const { getChoiceText } = useChoices({
        optionText,
    });

    useEffect(() => {
        const nodes = removeEmpty(
            createDataTree(
                choices.map(node => {
                    return {
                        id: node.id,
                        value: node.id,
                        label: getChoiceText(node),
                        parent_id: node.parent_id ?? null,
                    }
                })
            )
        );
        setNodes(nodes);
    }, [choices, getChoiceText])

    return (
        <>
            <CheckboxTree
                nodes={nodes}
                checked={checkedF}
                expanded={expandedF}
                onCheck={checked => {
                    console.log(checked);
                    setChecked(checked);
                    form.change(source, checked);
                }}
                onExpand={expanded => setExpanded(expanded)}
                icons={{
                    check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon="check-square" />,
                    uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={['fas', 'square']} />,
                    halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon="check-square" />,
                    expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon="chevron-right" />,
                    expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon="chevron-down" />,
                    expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon="plus-square" />,
                    collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon="minus-square" />,
                    parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
                    parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
                    leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon="file" />
                }}
            />
        </>
    )
}

export default CategoriesTree;