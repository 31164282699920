import * as React from "react";
import { TextInput, Create, SimpleForm, useNotify, TranslatableInputs, BooleanInput, DateInput, SelectInput, ImageInput, ImageField } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import { locales } from "../../lib/utils";

const LanguageCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create language: ${Errors.handleError(error.message)}`, 'error')
    };

    let types = [
        { id: 'ALERT', name: 'Alerta' },
        { id: 'NOTIFICATION', name: 'Notificação' },
    ]

    return (
        <Create onFailure={onFailure} {...props}>
            <SimpleForm redirect="show">
                <SelectInput source="type" defaultValue="NOTIFICATION" optionValue="id" choices={types} validate={validateRequired} label="Tipo" />
                <TranslatableInputs locales={locales}>
                    <TextInput source="title" label="Título" />
                    <TextInput source="description" label="Descrição" />
                    <BooleanInput source="send" label="Enviar nesta lingua" />
                </TranslatableInputs>
                <ImageInput source="image" label="Imagem" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <DateInput source="finalDate" label="Data final" />
            </SimpleForm>
        </Create>
    )
};

export default LanguageCreate;