import React from "react";
import { TranslatableInputs, TextInput, Create, TabbedForm, FormTab, useNotify, BooleanInput, useLocale, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, ArrayInput, SimpleFormIterator, NumberInput, FormDataConsumer } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import RichTextInput from 'ra-input-rich-text';
import { toolbarOptions } from "../../lib/editorOptions";
import GoogleMapInput from "../../components/GoogleMapInput";
import { locales } from "../../lib/utils";
import { Divider } from "@material-ui/core";

const mapOptions = {
    clickableIcons: false,
}

const scheduleDays = [
    { key: 'monday', name: 'segunda-feira' },
    { key: 'tuesday', name: 'terça-feira' },
    { key: 'wednesday', name: 'quarta-feira' },
    { key: 'thursday', name: 'quinta-feira' },
    { key: 'friday', name: 'sexta-feira' },
    { key: 'saturday', name: 'sábado' },
    { key: 'sunday', name: 'domingo' },
];

const PoiCreate = (props) => {
    const notify = useNotify();
    const locale = useLocale();
    const type = sessionStorage.getItem('poi_type');

    const onFailure = (error) => {
        notify(`Could not create Poi: ${Errors.handleError(error.message)}`, 'error')
    };

    let title = "";
    switch (type) {
        case 'pois':
            title = 'Novo ponto de interesse';
            break;
        case 'taxis':
            title = 'Novo taxi';
            break
        case 'eletric':
            title = 'Novo carregador de elétricos';
            break
        case 'park':
            title = 'Novo parque de estacionamento';
            break
        default:
            title = 'Novo ponto de interesse';
            break;
    }

    return (
        <Create title={title} onFailure={onFailure} {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="Conteúdo">
                    <TranslatableInputs locales={locales}>
                        <TextInput source="title" validate={validateRequired} />
                        <RichTextInput source="content" toolbar={toolbarOptions} label="Conteúdo" />
                        <RichTextInput source="more_info" toolbar={toolbarOptions} label="Informação útil" />
                        <BooleanInput source="published" />
                    </TranslatableInputs>
                    <ReferenceArrayInput source="categories_ids" reference="categories" label="Categorias" filter={{ parent_id: 'dropdown' }}>
                        <SelectArrayInput optionText={`name[${locale}]`} />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="tags_ids" reference="tags">
                        <SelectArrayInput optionText={`name[${locale}]`} />
                    </ReferenceArrayInput>
                    <NumberInput source="rate" label="Rating" />
                </FormTab>
                <FormTab label="Imagens">
                    <ImageInput source="image" label="Imagem de capa" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <ImageInput source="gallery" label="Galeria" accept="image/*" multiple>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Contactos">
                    <ArrayInput source="contacts[phone]" label="Telefones">
                        <SimpleFormIterator>
                            <TextInput label="Telefone" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="contacts[url]" label="Websites">
                        <SimpleFormIterator>
                            <TextInput label="Website" type="url" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="contacts[email]" label="Emails">
                        <SimpleFormIterator>
                            <TextInput label="Email" type="email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Horários">
                    {scheduleDays.map(day => {
                        return (
                            <>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <BooleanInput source={`schedule[${day.key}][open]`} label={day.name} style={{ minWidth: '200px' }} />
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.schedule && formData.schedule[day.key] && formData.schedule[day.key].open) {
                                                return <div style={{ height: '50px', paddingTop: '10px', fontWeight: 'bold' }}>Aberto</div>;
                                            }
                                            return <div style={{ height: '50px', paddingTop: '10px', fontWeight: 'bold' }}>Fechado</div>;
                                        }}
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.schedule && formData.schedule[day.key] && formData.schedule[day.key].open) {
                                                return (
                                                    <>
                                                        <BooleanInput source={`schedule[${day.key}][allday]`} label="24h" defaultValue={true} style={{ marginLeft: '20px' }} />
                                                        {!formData.schedule[day.key].allday && (
                                                            <ArrayInput source={`schedule[${day.key}][times]`} label="Horários" style={{ marginLeft: '20px', flex: 1 }}>
                                                                <SimpleFormIterator>
                                                                    <TextInput
                                                                        source="start_time"
                                                                        label="Horário de abertura"
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                    <TextInput
                                                                        source="end_time"
                                                                        label="Horário de fecho"
                                                                        type="time"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </SimpleFormIterator>
                                                            </ArrayInput>
                                                        )}
                                                    </>
                                                )
                                            }
                                        }}
                                    </FormDataConsumer>
                                </div>
                                <Divider
                                    style={{
                                        margin: '10px 0px',
                                    }}
                                />
                            </>
                        );
                    })}
                </FormTab>
                <FormTab label="Localização">
                    <GoogleMapInput source="location" zoom={12} validate={validateRequired} options={mapOptions} />
                    <TextInput multiline source="address" label="Endereço" />
                </FormTab>
                <FormTab label="Notificações">
                    <BooleanInput source="notification[enabled]" label="Ativar notificação para este POI" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return ((formData.notification && formData.notification.enabled) && <TextInput source="notification[content]" label="Descrição" multiline {...rest} />)
                        }}
                    </FormDataConsumer>

                </FormTab>
                <FormTab label="Google Services">
                    <TextInput source="google_place_id" label="Google Place ID" />
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default PoiCreate;