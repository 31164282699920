import * as React from "react";
import { TextInput, Create, SimpleForm, useNotify } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";

const CityFeatureCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create city: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Nova funcionalidade" onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={validateRequired} label="Nome" />
                <TextInput source="description" label="Descrição" />
            </SimpleForm>
        </Create>
    )
};

export default CityFeatureCreate;