import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import { useQuery } from "react-admin";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { locales, allLanguages } from "../../lib/utils";

const InfoPanel = ({ resource, filter = {}, title, col = 3, icon }) => {
  const useCustomLink = makeStyles((themes) => ({
    root: {
      textDecoration: "none",
      color: "#028FED",
    },
  }));

  const { total } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter,
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });

  const { total: published_total_pt } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "pt",
          state: true,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });
  const { total: not_published_total_pt } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "pt",
          state: false,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });

  const { total: published_total_en } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "en",
          state: true,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });
  const { total: not_published_total_en } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "en",
          state: false,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });

  const { total: published_total_fr } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "fr",
          state: true,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });
  const { total: not_published_total_fr } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "fr",
          state: false,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });

  const { total: published_total_es } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "es",
          state: true,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });
  const { total: not_published_total_es } = useQuery({
    type: "GET_LIST",
    resource,
    payload: {
      filter: {
        published: {
          locale: "es",
          state: false,
        },
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      sort: {
        sortField: "createdAt",
        sortOrder: "DESC",
      },
    },
  });

  const customLink = useCustomLink();

  const Icon = icon;

  return (
    <Grid item>
      <Card
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CardHeader
          avatar={<Icon style={{ color: "#028FED" }} />}
          title={title}
          subheader={`${total}`}
        />
        <CardContent style={{ flex: 1 }}>
          {total > 0 && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    {locales.map((locale, i) => (
                      <TableCell>{allLanguages[i].name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableRow>
                  <TableCell>Publicados</TableCell>
                  <TableCell>{published_total_pt}</TableCell>
                  <TableCell>{published_total_fr}</TableCell>
                  <TableCell>{published_total_en}</TableCell>
                  <TableCell>{published_total_es}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Não Publicados</TableCell>
                  <TableCell>{not_published_total_pt}</TableCell>
                  <TableCell>{not_published_total_fr}</TableCell>
                  <TableCell>{not_published_total_en}</TableCell>
                  <TableCell>{not_published_total_es}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          )}
          {total === 0 && `Não existem ${title.toLowerCase()} por publicar`}
        </CardContent>
        <CardActions>
          <Link
            to={{
              pathname: `/${resource}`,
            }}
            className={customLink.root}
          >
            <Button size="small" className={customLink.root}>
              Ver todos
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default InfoPanel;
