import * as React from "react";
import { List, Datagrid, DateField, TextField, Filter, TextInput } from 'react-admin';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
        <TextInput label="Username" source="username" alwaysOn resettable />
        <TextInput label="Email" source="email" alwaysOn resettable />
    </Filter>
);

export const UserList = (props) => {
    return (
        <List title="Utilizadores" {...props} filters={<UserFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="name" label="Nome" />
                <TextField source="email" />
                <TextField source="username" />
                <DateField source="createdAt" label="Criado" />
            </Datagrid>
        </List>
    )
};

export default UserList;