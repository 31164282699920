import React from 'react';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EventIcon from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Title } from 'react-admin';
import InfoPanel from './InfoPanel';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Title title="MyCity Admin" />
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6}>
          <Grid direction="column" container spacing={2}>
            <InfoPanel resource="articles" title="Notícias" icon={MenuBookIcon} />
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Grid direction="column" container spacing={2}>
            <InfoPanel resource="pois" title="Pois" icon={EventIcon} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;