import { client } from "./customDataProvider";
import gql from "graphql-tag";

const SIGNIN = gql`
  mutation signIn($user: String!, $password: String!) {
    signIn(user: $user, password: $password) {
      token
    }
  }
`;

const ME = gql`
  query {
    me {
      id
      username
      name
      language_id
      cities_ids
      role
      avatar {
        src
      }
    }
  }
`;

const LANGUAGE = gql`
    query language($languageId: ID!) {
        Language(id: $languageId) {
          id
          locale
        },
        allLanguages {
          id,
          name,
          locale,
          flag
        }
      }
`;

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const {
        data: {
          signIn: { token },
        },
      } = await client.mutate({
        mutation: SIGNIN,
        variables: {
          user: username,
          password,
        },
      });
      localStorage.setItem("x-token", token);
      const { data } = await client.query({
        query: ME,
        fetchPolicy: "network-only",
      });
      const language = await client.query({
        query: LANGUAGE,
        variables: {
          languageId: data.me.language_id
        }
      });
      localStorage.setItem('locale_id', language.data.Language.id);
      localStorage.setItem('city_id', data.me.cities_ids[0]);
      localStorage.setItem('cities_ids', JSON.stringify(data.me.cities_ids));
      localStorage.setItem('permissions', JSON.stringify(data.me.role));
      localStorage.setItem('user', JSON.stringify(data.me));
      localStorage.setItem('languages', JSON.stringify(language.data.allLanguages));
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.message);
    }
  },
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.message === 'GraphQL error: NoAuthenticatedAsUser') {
      localStorage.clear()
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    try {
      const { data } = await client.query({
        query: ME,
        fetchPolicy: "network-only",
      });
      if (data && localStorage.getItem("x-token")) {
        return Promise.resolve();
      }
      localStorage.clear()
      return Promise.reject("No ID");
    } catch (e) {
      localStorage.clear()
      return Promise.reject();
    }
  },
  getPermissions: () => {
    const role = JSON.parse(localStorage.getItem('permissions'));
    return role ? Promise.resolve(role) : Promise.reject();
  }
};

export default authProvider;