import * as React from "react";
import { TextInput, Create, SimpleForm, useNotify, BooleanInput, ImageInput, ImageField, TranslatableInputs } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import GoogleMapInput from "../../components/GoogleMapInput";
import { locales } from "../../lib/utils";
import DescriptionField from "../../components/DescriptionField";

const CityTitle = ({ record }) => {
    return <span>Nova Cidade</span>;
};

const CityCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create city: ${Errors.handleError(error.message)}`, 'error')
    };

    const mapOptions = {
        clickableIcons: false,
    }

    return (
        <Create title={<CityTitle />} onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="name" validate={[validateRequired]} label="Nome"/>
                </TranslatableInputs>
                <ImageInput source="image" label="Imagem de capa" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="icon" label="Icon" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <BooleanInput source="published" label="Publicado" />
                <TextInput source="email" validate={[validateRequired]} label="Email" />
                <DescriptionField content="Insira a longitude e latitude nos campos indicados" />
                <GoogleMapInput source="location" zoom={12} validate={validateRequired} options={mapOptions} />
            </SimpleForm>
        </Create>
    );
};

export default CityCreate;