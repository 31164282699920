import React from "react";
import { TextInput, Create, SimpleForm, useNotify, BooleanInput, useLocale, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, TranslatableInputs } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import RichTextInput from 'ra-input-rich-text';
import { toolbarOptions } from "../../lib/editorOptions";
import { locales } from '../../lib/utils';

const ArticleCreate = (props) => {
    const notify = useNotify();
    const locale = useLocale();

    const onFailure = (error) => {
        notify(`Could not create city: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Nova notícia" onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="title" validate={validateRequired} label="Título" />
                    <RichTextInput source="content" validate={validateRequired} toolbar={toolbarOptions} label="Conteúdo" />
                    <BooleanInput source="highlighted" label="Destacada" />
                    <BooleanInput source="published" label="Publicado" />
                </TranslatableInputs>
                <ReferenceArrayInput source="categories_ids" reference="categories" label="Categorias" filter={{ parent_id: 'dropdown' }}>
                    <SelectArrayInput optionText={`name[${locale}]`} />
                </ReferenceArrayInput>
                <ImageInput source="image" label="Imagem de capa" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="gallery" label="Galeria" accept="image/*" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
};

export default ArticleCreate;