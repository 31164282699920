import * as React from "react";
import { List, Datagrid, DateField, TextField, Filter, TextInput } from 'react-admin';

const IncidentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const IncidentList = (props) => {
    return (
        <List {...props} filters={<IncidentFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="show">
                <TextField source="title" />
                <DateField source="createdAt" />
            </Datagrid>
        </List>
    )
};

export default IncidentList;