import React from "react";
import { useQueryWithStore, useRefresh, LinearProgress, Error, useRedirect, useLocale } from 'react-admin';
import { Select, MenuItem, Typography, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import CityIcon from '@material-ui/icons/LocationCity';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: theme.color,
        '&::before': {
            borderBottom: "1px solid " + theme.color,
        },
        '&:hover:not(.Mui-disabled)::before': {
            borderBottom: "2px solid " + theme.color,
        },
        '&::after': {
            borderBottom: "2px solid " + theme.color
        },
        '& > .MuiSelect-icon': {
            color: theme.color
        }
    },
}));

const useListStyles = makeStyles((theme) => ({
    root: {
        minWidth: '40px',
    }
}));

const CitiesSwitcher = props => {
    const classes = useStyles();
    const listClasses = useListStyles();
    const refresh = useRefresh()
    const redirect = useRedirect();
    const locale = useLocale();

    const payload = {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
    };
    const { loaded, error, data } = useQueryWithStore({
        type: 'getList',
        resource: 'cities',
        payload
    });

    const myCities = JSON.parse(localStorage.getItem('cities_ids'));

    let cities = [];
    if (myCities) {
        cities = _.filter(data, city => myCities.includes(city.id));
    }

    const handleChange = (event, child) => {
        localStorage.setItem('city_id', event.target.value);
        const titleDiv = document.querySelectorAll('#city-title')[0];
        titleDiv.innerHTML = child.props.children.props.children;
        props.onChange();
        refresh();
        redirect('/');
    };

    if (!loaded) { return <LinearProgress />; }
    if (error) { return <Error />; }
    if (cities.length > 1) {

        return (
            <>
                <MenuItem>
                    <ListItemIcon classes={listClasses}>
                        <CityIcon />
                    </ListItemIcon>
                    <Select
                        className={classes.root}
                        onChange={handleChange}
                        value={localStorage.getItem('city_id')}
                        id="city-switcher"
                    >
                        {
                            _.map(cities, city => {
                                return (
                                    <MenuItem value={city.id} key={city.id}>
                                        <Typography variant="inherit">{city.name[locale]}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </MenuItem>
            </>
        );
    }
    return null
};

export default CitiesSwitcher;