
let allLanguages = JSON.parse(localStorage.getItem('languages'));

if (!allLanguages) {
    allLanguages = [{
        locale: 'pt'
    }]
}

const locales =  allLanguages.map(language => language.locale);

console.log(allLanguages);

export { locales, allLanguages };