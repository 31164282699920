import React, { useEffect } from 'react';
import { Layout, AppBar } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import MyUserMenu from './MyUserMenu';
import MyMenu from './MyMenu';
import logo from '../assets/images/logo.png';

const MyAppBar = props => {
  useEffect(() => {
    const citySwitcher = document.querySelectorAll('#city-switcher > span')[0]
    const titleDiv = document.querySelectorAll('#city-title')[0];
    if (citySwitcher) {
      titleDiv.innerHTML = citySwitcher.textContent;
    }
  });

  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <Box flex="1">
        <div style={{
          maxHeight: '50px',
          display: 'flex',
          alignItems: 'center',
        }}>
          <img src={logo} alt="Logo" style={{
            width: '100px',
            height: 'auto',
          }} />
          <Typography variant="h6" id="city-title" style={{ marginLeft: '15px' }}>Braga</Typography>
        </div>
      </Box>
    </AppBar >
  );
};

const MyLayout = props => <Layout {...props} appBar={MyAppBar} menu={MyMenu}>
  <Typography variant="h6" id="react-admin-title" style={{ marginTop: '15px', marginBottom: '20px', color: "#fff" }}></Typography>
  {props.children}
</Layout>;

export default MyLayout;