import * as React from "react";
import { TextInput, Edit, SimpleForm, TranslatableInputs, useLocale } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import { ColorField, ColorInput } from 'react-admin-color-input';
import { locales } from "../../lib/utils";

const TagTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? record.name[locale] : ''}</span>;
};

const TagEdit = (props) => {
    return (
        <Edit title={<TagTitle />} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="name" validate={validateRequired} title="Nome" />
                </TranslatableInputs>
                <ColorInput source="color" label="Cor" pickker="Sketch" validate={validateRequired} />
                <ColorField source="color" label="Cor" />
            </SimpleForm>
        </Edit>
    )
};

export default TagEdit;