import buildGraphQLProvider, {
    buildQuery as buildQueryFactory,
} from "ra-data-graphql-simple";
import { ApolloClient, InMemoryCache, from } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error'
import jwt from "jsonwebtoken";
import { CREATE, GET_LIST, UPDATE } from 'react-admin'
import _ from "lodash";

const getGqlResource = (resource) => {
    switch (resource) {
        case "users":
            return "User";
        case "cities":
            return "City";
        case "cityFeatures":
            return "CityFeature";
        case "languages":
            return "Language";
        case "articles":
            return "Article";
        case "events":
            return "Event";
        case "categories":
            return "Category";
        case "tags":
            return "Tag";
        case "pois":
            return "Poi";
        case "courses":
            return "Course";
        case "incidents":
            return "Incident";
        case "pages":
            return "Page";
        case "notifications":
            return "OneSignalNotification";
        case "signposting":
            return "Signposting";
        default:
            throw new Error(`Unknown resource ${resource}`);
    }
};

const customBuildQuery = (introspectionResults) => {
    const buildQuery = buildQueryFactory(introspectionResults);

    return (type, resource, params) => {

        if (type === UPDATE && resource === 'User') {
            localStorage.setItem('cities_ids', JSON.stringify(params.data.cities_ids));
        }

        const builtQuery = buildQuery(type, resource, params);

        return builtQuery;
    };
};

/* const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
}); */

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = localStorage.getItem("x-token");
    // return the headers to the context so httpLink can read them
    jwt.verify(token, process.env.REACT_APP_SECRET, (err, decoded) => {
        if (err) {
            localStorage.removeItem("x-token");
        }
    });

    return {
        headers: {
            ...headers,
            "locale_id": localStorage.getItem('locale_id'),
            "city_id": localStorage.getItem('city_id'),
            "x-token": token || "",
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }, index) => {
            if (message === "GraphQL error: NoAuthenticatedAsUser") {
                localStorage.removeItem("x-token");
                localStorage.removeItem('user');
            }
            console.log(
                `Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
        });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
});

export { client };

const convertURLToFile = (image) => {
    return new Promise((resolve, reject) => {
        fetch(image.src)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                resolve(new File([blob], image.filename, { lastModified: new Date(), type: image.mimetype }));
            }).catch(err => reject);
    })
}

const convertURLToFileArray = async (images) => {
    const files = [];
    for (const image of images) {
        if (image.rawFile) {
            files.push(image.rawFile);
        } else {
            const response = await fetch(image.src);
            const blob = await response.blob();
            const file = await new File([blob], image.filename, { lastModified: new Date(), type: image.mimetype });
            files.push(file);
        }
    }
    return files;
}

const provider = () => {
    return buildGraphQLProvider({
        client,
        buildQuery: customBuildQuery,
    }).then((dataProvider) => async (type, resource, params) => {

        if (type === CREATE)
            console.log(params);

        if (type === CREATE && resource === 'pois') {
            params.data.type = sessionStorage.getItem('poi_type');
        }

        if (type === GET_LIST && resource === 'pois') {
            params.filter.type = sessionStorage.getItem('poi_type');
        }

        if ((type === CREATE || type === UPDATE) && resource === 'users') {
            if (type === CREATE) {
                let newParams = params;
                if (params.data.avatar) {
                    const { data, ...rest_params } = newParams;
                    newParams = { ...rest_params, data: { ...data, avatar: data.avatar.rawFile } };
                }
                return dataProvider(type, getGqlResource(resource), newParams);
            }
            if (type === UPDATE) {
                let newParams = params;
                if (params.data.avatar) {
                    const { data, ...rest_params } = newParams;
                    if (_.isEmpty(params.data.avatar)) {
                        newParams = { ...rest_params, data: { ...data, avatar: undefined } };
                    } else {
                        if (!data.avatar.rawFile) {
                            const file = await convertURLToFile(data.avatar);
                            newParams = { ...rest_params, data: { ...data, avatar: file } };
                        } else {
                            newParams = { ...rest_params, data: { ...data, avatar: data.avatar.rawFile } };
                        }
                    }
                }
                return dataProvider(type, getGqlResource(resource), newParams);
            }
        }

        if ((type === CREATE || type === UPDATE) && (resource === 'articles' || resource === 'events' || resource === 'pois' || resource === 'courses' || resource === 'categories' || resource === 'cities' || resource === 'notifications' || resource === 'signposting')) {
            if (type === CREATE) {
                let newParams = params;
                if (params.data.image) {
                    const { data, ...rest_params } = newParams;
                    newParams = { ...rest_params, data: { ...data, image: data.image.rawFile } };
                }
                if (params.data.icon) {
                    const { data, ...rest_params } = newParams;
                    newParams = { ...rest_params, data: { ...data, icon: data.icon.rawFile } };
                }
                if (params.data.pin_image) {
                    const { data, ...rest_params } = newParams;
                    newParams = { ...rest_params, data: { ...data, pin_image: data.pin_image.rawFile } };
                }
                if (params.data.gallery) {
                    const { data, ...rest_params } = newParams;
                    const files = _.map(data.gallery, file => file.rawFile);
                    newParams = { ...rest_params, data: { ...data, gallery: files } };
                }
                return dataProvider(type, getGqlResource(resource), newParams);
            }
            if (type === UPDATE) {
                let newParams = params;
                if (params.data.image) {
                    const { data, ...rest_params } = newParams;
                    if (_.isEmpty(params.data.image)) {
                        newParams = { ...rest_params, data: { ...data, image: undefined } };
                    } else {
                        if (!data.image.rawFile) {
                            const file = await convertURLToFile(data.image);
                            newParams = { ...rest_params, data: { ...data, image: file } };
                        } else {
                            newParams = { ...rest_params, data: { ...data, image: data.image.rawFile } };
                        }
                    }
                }
                if (params.data.icon) {
                    const { data, ...rest_params } = newParams;
                    if (_.isEmpty(params.data.icon)) {
                        newParams = { ...rest_params, data: { ...data, icon: undefined } };
                    } else {
                        if (!data.icon.rawFile) {
                            const file = await convertURLToFile(data.icon);
                            newParams = { ...rest_params, data: { ...data, icon: file } };
                        } else {
                            newParams = { ...rest_params, data: { ...data, icon: data.icon.rawFile } };
                        }
                    }
                }
                if (params.data.pin_image) {
                    const { data, ...rest_params } = newParams;
                    if (_.isEmpty(params.data.pin_image)) {
                        newParams = { ...rest_params, data: { ...data, pin_image: undefined } };
                    } else {
                        if (!data.pin_image.rawFile) {
                            const file = await convertURLToFile(data.pin_image);
                            newParams = { ...rest_params, data: { ...data, pin_image: file } };
                        } else {
                            newParams = { ...rest_params, data: { ...data, pin_image: data.pin_image.rawFile } };
                        }
                    }
                }
                if (params.data.gallery) {
                    const { data, ...rest_params } = newParams;
                    const files = await convertURLToFileArray(data.gallery);
                    newParams = { ...rest_params, data: { ...data, gallery: files } };
                }
                if (params.data.eventPage.length > 0) {
                    const { data, ...rest_params } = newParams;

                    for (let i = 0; i < params.data.eventPage.length; i++) {
                        if (_.isEmpty(params.data.eventPage[i].image)) {
                            newParams = { ...rest_params, data: { ...data } };
                        } else {
                            if (!data.eventPage[i].image.rawFile) {
                                const file = await convertURLToFile(data.eventPage[i].image);
                                data.eventPage[i] = { ...data.eventPage[i], image: file }
                                newParams = { ...rest_params, data: { ...data, eventPage: [ ...data.eventPage ] } };
                            } else {
                                data.eventPage[i] = { ...data.eventPage[i], image: data.eventPage[i].image.rawFile }
                                newParams = { ...rest_params, data: { ...data, eventPage: [ ...data.eventPage ] } };
                            }
                        }
                    }
                }
                return dataProvider(type, getGqlResource(resource), newParams);
            };
        }
        return dataProvider(type, getGqlResource(resource), params)
    });
};

export default provider;