import React from "react";
import { TranslatableInputs, TextInput, Create, TabbedForm, FormTab, useNotify, BooleanInput, ImageInput, ImageField, FormDataConsumer } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import GoogleMapInput from "../../components/GoogleMapInput";
import { locales } from "../../lib/utils";

const mapOptions = {
    clickableIcons: false,
}

const SignpostingCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create Signposting: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title={"Novo ponto de sinalética"} onFailure={onFailure} {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="Conteúdo">
                    <TranslatableInputs locales={locales}>
                        <TextInput source="title" validate={validateRequired} />
                    </TranslatableInputs>
                </FormTab>
                <FormTab label="Imagens">
                    <ImageInput source="image" label="Imagem de capa" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Localização">
                    <GoogleMapInput source="location" zoom={12} validate={validateRequired} options={mapOptions} />
                </FormTab>
                <FormTab label="Notificações">
                    <BooleanInput source="notification[enabled]" label="Ativar notificação para este POI" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return ((formData.notification && formData.notification.enabled) && <TextInput source="notification[content]" label="Descrição" multiline {...rest} />)
                        }}
                    </FormDataConsumer>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default SignpostingCreate;