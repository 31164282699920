import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { TextInput, Edit, BooleanInput, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, SimpleForm, useLocale, TranslatableInputs } from 'react-admin';
import { toolbarOptions } from "../../lib/editorOptions";
import { locales } from "../../lib/utils";
import { validateRequired } from "../../lib/validations";

const ArticleTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? record.title[locale] : ''}</span>;
};

const ArticleEdit = (props) => {
    const locale = useLocale();
    return (
        <Edit title={<ArticleTitle />} {...props}>
            <SimpleForm redirect="edit">
                <TranslatableInputs locales={locales}>
                    <TextInput source="title" validate={validateRequired} label="Título" />
                    <RichTextInput source="content" toolbar={toolbarOptions} validate={validateRequired} label="Conteúdo" />
                    <BooleanInput source="highlighted" label="Destacada" />
                    <BooleanInput source="published" label="Publicado" />
                </TranslatableInputs>
                <ReferenceArrayInput source="categories_ids" reference="categories" label="Categorias" filter={{ parent_id: 'dropdown' }}>
                    <SelectArrayInput optionText={`name[${locale}]`} />
                </ReferenceArrayInput>
                <ImageInput source="image" label="Imagem de capa" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput source="gallery" label="Galeria" accept="image/*" multiple>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
};

export default ArticleEdit;