import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, EditButton, ImageField } from 'react-admin';

const LanguageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

const LanguageList = (props) => {
    return (
        <List {...props} filters={<LanguageFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <ImageField source="flag" title="Flag" />
                <TextField source="name" />
                <TextField source="locale" />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export default LanguageList;