import * as React from "react";
import RichTextInput from "ra-input-rich-text";
import { TextInput, Edit, TabbedForm, FormTab, BooleanInput, ReferenceArrayInput, SelectArrayInput, ImageInput, ImageField, FormDataConsumer, TranslatableInputs, ArrayInput, SimpleFormIterator, useLocale } from 'react-admin';
import DescriptionField from "../../components/DescriptionField";
import GoogleMapInput from "../../components/GoogleMapInput";
import { toolbarOptions } from "../../lib/editorOptions";
import { locales } from "../../lib/utils";
import { validateRequired } from "../../lib/validations";

const CityTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? record.name[locale] : ''}</span>;
};

const CityEdit = (props) => {
    const mapOptions = {
        clickableIcons: false,
    }

    return (
        <Edit title={<CityTitle />} {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="Informação">
                    <TextInput disabled source="id" />
                    <TranslatableInputs locales={locales}>
                        <TextInput source="name" validate={[validateRequired]} label="Nome" />
                    </TranslatableInputs>
                    <ImageInput source="image" label="Imagem de capa" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <ImageInput source="icon" label="Icon" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <BooleanInput source="published" label="Publicado" />
                    <ReferenceArrayInput source="features_ids" reference="cityFeatures" label="Funcionalidades">
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                </FormTab>
                <FormTab label="Contactos">
                    <TextInput source="email" validate={[validateRequired]} label="Email" />
                </FormTab>
                <FormTab label="Localização">
                    <DescriptionField content="Insira a longitude e latitude nos campos indicados" />
                    <GoogleMapInput source="location" zoom={12} validate={validateRequired} options={mapOptions} />
                </FormTab>
                <FormTab label="Eventos">
                    <ArrayInput source="eventPage" label="Evento">
                        <SimpleFormIterator>
                            <FormDataConsumer>
                                {({ getSource, ...rest }) => {
                                    return (
                                        <TranslatableInputs locales={locales}>
                                            <RichTextInput toolbar={toolbarOptions} source={getSource("title")} label="Título" />
                                        </TranslatableInputs>

                                    );
                                }}
                            </FormDataConsumer>
                            <ImageInput source="image" label="Imagem de capa" accept="image/*">
                                <ImageField source="src" title="title" />
                            </ImageInput>
                            <TextInput label="Url" source="url" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default CityEdit;