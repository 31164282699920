import "./App.css";
import * as React from "react";
import { Component } from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import portugueseMessages from "@henriko/ra-language-portuguese";
import polyglotI18nProvider from "ra-i18n-polyglot";

import authProvider from "./lib/authProvider";
import dataProviderFactory from "./lib/customDataProvider";

import Dashboard from "./components/dashboard/index";

// Resources
import { UserList, UserEdit, UserCreate } from "./resources/users";
import { CityCreate, CityEdit, CityList } from "./resources/cities";
import {
  CityFeatureCreate,
  CityFeatureEdit,
  CityFeatureList,
} from "./resources/cityFeatures";
import {
  LanguageCreate,
  LanguageList,
  LanguageEdit,
} from "./resources/languages";
import { ArticleList, ArticleCreate, ArticleEdit } from "./resources/articles";
import { EventList, EventCreate, EventEdit } from "./resources/events";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
} from "./resources/categories";
import { TagCreate, TagEdit, TagList } from "./resources/tags";
import { PoiCreate, PoiEdit, PoiList } from "./resources/pois";
import { CourseCreate, CourseEdit, CourseList } from "./resources/courses";
import { IncidentList, IncidentShow } from "./resources/incidents";
import { PageCreate, PageEdit, PageList } from "./resources/pages";
import {
  NotificationCreate,
  NotificationList,
  NotificationShow,
} from "./resources/notifications";
import {
  SignpostingCreate,
  SignpostingEdit,
  SignpostingList,
} from "./resources/signposting";

import UserIcon from "@material-ui/icons/Face";
import CityIcon from "@material-ui/icons/LocationCity";
import LanguageIcon from "@material-ui/icons/Language";

import Loading from "./components/Loading";
import MyLayout from "./components/MyLayout";
import { Tub, Trips, Trip, Agencies } from "./components/mobilidade/Tub";
import myCityTheme from "./lib/theme";
import { ADMIN, EDITOR, SUPERADMIN } from "./lib/roles";
import { allLanguages } from "./lib/utils";
import ImportPoi from "./components/ImportPoi";
import BulkPoi from "./components/ImportBulkPoi";

const customRoutes = [
  <Route exact path="/importPoi" component={ImportPoi} />,
  <Route exact path="/bulkPoi" component={BulkPoi} />,
  <Route exact path="/transportes" component={Agencies} />,
  <Route exact path="/transportes/routes/:id" component={Tub} />,
  <Route exact path="/transportes/route/:id" component={Trips} />,
  <Route exact path="/transportes/trip/:id" component={Trip} />,
];

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }

  async componentDidMount() {
    const dataProvider = await dataProviderFactory();
    this.setState({ dataProvider });
  }

  render() {
    const { dataProvider } = this.state;

    let messages = {};

    allLanguages.forEach((language) => {
      messages[language.locale] = portugueseMessages;
    });

    const i18nProvider = polyglotI18nProvider(
      (locale) => messages[locale],
      "pt"
    );

    if (!dataProvider) {
      return <Loading />;
    }

    return (
      <Admin
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={MyLayout}
        customRoutes={customRoutes}
        theme={myCityTheme}
      >
        {(permissions) => [
          <Resource
            name="users"
            list={permissions.includes(ADMIN) ? UserList : null}
            edit={permissions.includes(EDITOR) ? UserEdit : null}
            create={permissions.includes(ADMIN) ? UserCreate : null}
            icon={UserIcon}
          />,
          <Resource
            name="cities"
            list={permissions.includes(ADMIN) ? CityList : null}
            edit={permissions.includes(ADMIN) ? CityEdit : null}
            create={permissions.includes(ADMIN) ? CityCreate : null}
            icon={CityIcon}
          />,
          <Resource
            name="cityFeatures"
            list={permissions.includes(SUPERADMIN) ? CityFeatureList : null}
            edit={permissions.includes(SUPERADMIN) ? CityFeatureEdit : null}
            create={permissions.includes(SUPERADMIN) ? CityFeatureCreate : null}
          />,
          <Resource
            name="pages"
            list={permissions.includes(ADMIN) ? PageList : null}
            edit={permissions.includes(ADMIN) ? PageEdit : null}
            create={permissions.includes(ADMIN) ? PageCreate : null}
          />,
          <Resource
            name="languages"
            list={permissions.includes(ADMIN) ? LanguageList : null}
            edit={permissions.includes(ADMIN) ? LanguageEdit : null}
            create={permissions.includes(ADMIN) ? LanguageCreate : null}
            icon={LanguageIcon}
          />,
          <Resource
            name="articles"
            list={ArticleList}
            edit={ArticleEdit}
            create={ArticleCreate}
          />,
          <Resource
            name="events"
            list={EventList}
            edit={EventEdit}
            create={EventCreate}
          />,
          <Resource
            name="pois"
            list={PoiList}
            edit={PoiEdit}
            create={PoiCreate}
          />,
          <Resource name="incidents" list={IncidentList} show={IncidentShow} />,
          <Resource
            name="categories"
            list={CategoryList}
            edit={CategoryEdit}
            create={CategoryCreate}
          />,
          <Resource
            name="tags"
            list={TagList}
            edit={TagEdit}
            create={TagCreate}
          />,
          <Resource
            name="courses"
            list={CourseList}
            edit={CourseEdit}
            create={CourseCreate}
          />,
          <Resource
            name="notifications"
            list={NotificationList}
            create={NotificationCreate}
            show={NotificationShow}
          />,
          <Resource
            name="signposting"
            list={SignpostingList}
            create={SignpostingCreate}
            edit={SignpostingEdit}
          />,
        ]}
      </Admin>
    );
  }
}

export default App;
