import * as React from "react";
import { TextInput, Edit, SimpleForm } from 'react-admin';
import { validateRequired } from "../../lib/validations";

const CityFeatureTitle = ({ record }) => {
    return <span>{record ? record.name : ''}</span>;
};

const CityEdit = (props) => {
    return (
        <Edit title={<CityFeatureTitle />} {...props}>
            <SimpleForm redirect="edit">
                <TextInput disabled source="id" />
                <TextInput source="name" validate={validateRequired} label="Nome" />
                <TextInput source="description" label="Descrição" />
            </SimpleForm>
        </Edit>
    )
};

export default CityEdit;