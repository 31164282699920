import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, useLocale } from 'react-admin';
import { ColorField } from 'react-admin-color-input';

const TagFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const TagList = (props) => {
    const locale = useLocale();
    return (
        <List {...props} filters={<TagFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source={`name[${locale}]`} label="Nome" />
                <ColorField source="color" label="Cor" />
            </Datagrid>
        </List>
    )
};

export default TagList;