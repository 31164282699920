import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(
    theme => ({
        root: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '5px',
            marginBottom: '20px',
        },
        username: {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            textDecoration: 'none'
        },
        avatar: {
            marginBottom: '10px',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            padding: '6px',
            border: `2px dashed ${theme.palette.primary.main}`,
        },
        image: {
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.primary.main
        }
    }));

const AvatarProfile = props => {
    const user = JSON.parse(localStorage.getItem('user'));
    const classes = useStyles();

    if (user) {
        const fullName = user.name.split(' ');
        let initials;
        if (fullName.length > 1) {
            initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        } else {
            initials = fullName.shift().charAt(0);
        }
        return (
            <div className={classes.root}>
                <div className={classes.avatar}>
                    <Avatar alt={user.name} src={user.avatar.src} className={classes.image}>
                        {initials.toUpperCase()}
                    </Avatar>
                </div>
                <div className={classes.username}>
                    <Link
                        className={classes.username}
                        to={`/users/${user.id}`}
                    >
                        {user.name}
                    </Link>
                </div>
            </div >
        );
    }
    return null;
}

export default AvatarProfile;