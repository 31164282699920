import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import GoogleMapField from "../../components/GoogleMapField";

const IncidentShow = (props) => {
    const mapOptions = {
        zoomControl: false,
        gestureHandling: "none",
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    }

    const IncidentTitle = ({ record }) => {
        return <span>Incident - {record ? record.name : ''}</span>;
    };

    return (
        <Show  title={<IncidentTitle />} {...props}>
            <SimpleShowLayout>
                <TabbedShowLayout>
                    <Tab label="summary">
                        <TextField source="title" label="Título" />
                        <TextField source="description" label="Descrição" />
                        <TextField source="email" label="Email do utilizador" />
                        <TextField source="category" label="Categoria" />
                        <ImageField source="gallery" src="src" />
                    </Tab>
                    <Tab label="Location">
                        <GoogleMapField
                            source="location"
                            zoom={12}
                            options={mapOptions}
                        />
                    </Tab>
                    <Tab label="Timestamps">
                        <DateField source="createdAt" showTime />
                        <DateField source="updatedAt" showTime />
                    </Tab>
                </TabbedShowLayout>
            </SimpleShowLayout>
        </Show>
    )
};

export default IncidentShow;