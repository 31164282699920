import * as React from "react";
import { List, Datagrid, TextField, BooleanField, useLocale, ImageField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    theme => ({
        image: {
            borderRadius: '5px',
            width: 'auto',
            maxHeight: '40px',
        }
    }));

export const CityList = (props) => {
    const locale = useLocale();
    const classes = useStyles();

    return (
        <List title="Cidades" {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <ImageField source="icon[src]" label="Imagem" classes={classes} />
                <BooleanField source="published" label="Publicado" />
                <TextField source={`name[${locale}]`} label="Nome" />
            </Datagrid>
        </List>
    )
};

export default CityList;