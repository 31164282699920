import * as React from "react";
import { TextInput, Create, SimpleForm, useNotify } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";

const LanguageCreate = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(`Could not create language: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create onFailure={onFailure} {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={validateRequired} />
                <TextInput source="locale" validate={validateRequired} />
            </SimpleForm>
        </Create>
    )
};

export default LanguageCreate;