import * as React from "react";
import { List, Datagrid, DateField, TextField, Filter, TextInput, useLocale } from 'react-admin';

const PageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const PageList = (props) => {
    const locale = useLocale();
    return (
        <List title="Páginas" {...props} filters={<PageFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source={`title[${locale}]`} label="Título" />
                <TextField source="slug" />
                <DateField source="createdAt" label="Criado em "/>
            </Datagrid>
        </List>
    )
};

export default PageList;