import React from 'react';
import PoiSearch from './PoiSearch';
import { Title } from 'react-admin';

const ImportPoi = props => {
    return (
        <div>
            <Title title="Importar ponto de interesse" />
            <PoiSearch />
        </div>
    )
}

export default ImportPoi;