import * as React from "react";
import { TextInput, Edit, SimpleForm, ImageField } from 'react-admin';
import { validateRequired } from "../../lib/validations";

const LanguageEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="edit">
                <TextInput source="name" validate={validateRequired} />
                <TextInput source="locale" validate={validateRequired} />
                <ImageField source="flag" title="Flag" />
            </SimpleForm>
        </Edit>
    )
};

export default LanguageEdit;