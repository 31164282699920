import * as React from "react";
import { List, Datagrid, DateField, TextField, ImageField, BooleanField, Filter, TextInput, useLocale } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { allLanguages } from "../../lib/utils";

const useStyles = makeStyles(
    theme => ({
        image: {
            borderRadius: '5px',
            width: 'auto',
            maxHeight: '40px',
        }
    }));

const PoiFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

export const PoiList = (props) => {
    const classes = useStyles();
    const locale = useLocale();
    const type = sessionStorage.getItem('poi_type');

    let title = "";
    switch (type) {
        case 'pois':
            title = 'Pontos de interesse';
            break;
        case 'taxis':
            title = 'Taxis';
            break
        case 'eletric':
            title = 'Carregamento elétricos';
            break
        case 'park':
            title = 'Parques de estacionamento';
            break
        default:
            title = 'Pontos de interesse';
            break;
    }
    return (
        <List title={title} {...props} filters={<PoiFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <ImageField source="image[src]" label="Imagem" classes={classes} />
                <TextField source={`title[${locale}]`} label="Título" />
                {allLanguages.map(language => <BooleanField source={`published[${language.locale}]`} label={<img src={language.flag} alt={language.locale} />} />)}
                <DateField source="createdAt" label="Criado" />
            </Datagrid>
        </List>
    )
};

export default PoiList;