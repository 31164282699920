import React from "react";
import { TranslatableInputs, TextInput, Create, TabbedForm, FormTab, useNotify, BooleanInput, useLocale, Toolbar, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, DateTimeInput, ArrayInput, SimpleFormIterator, FormDataConsumer, NumberInput } from 'react-admin';
import { validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import RichTextInput from 'ra-input-rich-text';
import SaveButtonAware from "../../components/SaveButtonAware";
import PropTypes from 'prop-types';
import { toolbarOptions } from "../../lib/editorOptions";
import { Grid } from "@material-ui/core";
import GoogleMapInput from "../../components/GoogleMapInput";
import { locales } from "../../lib/utils";

const CustomToolbar = props => {
    const { savedisabled, ...rest } = props;
    return (
        <Toolbar {...rest}>
            <SaveButtonAware disabled={savedisabled || props.invalid} />
        </Toolbar>
    );
}

CustomToolbar.propTypes = {
    savedisabled: PropTypes.bool.isRequired,
};

const mapOptions = {
    clickableIcons: false,
}

const EventCreate = (props) => {
    const notify = useNotify();
    const locale = useLocale();

    const onFailure = (error) => {
        notify(`Could not create event: ${Errors.handleError(error.message)}`, 'error')
    };

    return (
        <Create title="Novo evento" onFailure={onFailure} {...props}>
            <TabbedForm redirect="edit">
                <FormTab label="Conteúdo">
                    <TranslatableInputs locales={locales}>
                        <TextInput source="title" validate={validateRequired} label="Título" />
                        <RichTextInput source="content" toolbar={toolbarOptions} validate={validateRequired} label="Conteúdo" />
                        <RichTextInput source="more_info" toolbar={toolbarOptions} label="Informação útil" />
                        <BooleanInput source="published" label="Publicado" />
                    </TranslatableInputs>
                    <ReferenceArrayInput source="categories_ids" reference="categories" label="Categorias" filter={{ parent_id: 'dropdown' }}>
                        <SelectArrayInput optionText={`name[${locale}]`} />
                    </ReferenceArrayInput>
                </FormTab>
                <FormTab label="Imagens">
                    <ImageInput source="image" label="Imagem de capa" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <ImageInput source="gallery" label="Galeria" accept="image/*" multiple>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Preço">
                    <BooleanInput source="price[free]" label="Gratuito" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return ((!formData.price || !formData.price.free) && <NumberInput source="price[value]" {...rest} label="Preço" />)
                        }
                        }
                    </FormDataConsumer>
                    <TranslatableInputs locales={locales}>
                        <TextInput source={`price[info]`} label="Mais informações" />
                    </TranslatableInputs>
                </FormTab>
                <FormTab label="Contactos">
                    <ArrayInput source="contacts[phone]" label="Telefones">
                        <SimpleFormIterator>
                            <TextInput label="Telefone" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="contacts[url]" label="Websites">
                        <SimpleFormIterator>
                            <TextInput label="Website" type="url" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="contacts[email]" label="Emails">
                        <SimpleFormIterator>
                            <TextInput label="Email" type="email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Datas">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <DateTimeInput source="start_date" label="Data de inicio" validate={validateRequired} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimeInput source="end_date" label="Data de fim" validate={validateRequired} />
                        </Grid>
                    </Grid>
                    <ArrayInput source="schedule" label="Horários">
                        <SimpleFormIterator>
                            <DateTimeInput source="start_date" label="Horário de inicio" />
                            <DateTimeInput source="end_date" label="Horário de fim" />
                            <FormDataConsumer>
                                {({ getSource, ...rest }) => {
                                    return (
                                        <TranslatableInputs locales={locales}>
                                            <TextInput source={getSource("info")} label="Informação adiconal" />
                                        </TranslatableInputs>

                                    );
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Localização">
                    <GoogleMapInput source="location" zoom={12} validate={validateRequired} options={mapOptions} />
                    <TextInput multiline source="address" label="Endereço" />
                </FormTab>
            </TabbedForm>
        </Create>
    )
};

export default EventCreate;