import * as React from "react";
import { List, Datagrid, TextField, Filter, TextInput, useLocale } from 'react-admin';

const NotificationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn resettable />
    </Filter>
);

const NotificationList = (props) => {
    const locale = useLocale();
    return (
        <List {...props} filters={<NotificationFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid rowClick="show">
                <TextField source={`title[${locale}]`} label="Título" />
            </Datagrid>
        </List>
    )
};

export default NotificationList;