import * as React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  SelectInput,
  PasswordInput,
  useNotify,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { validateEmail, validateRequired } from "../../lib/validations";
import Errors from "../../lib/errors";
import { ADMIN, EDITOR, SUPERADMIN, USER, CITYADMIN } from "../../lib/roles";

const UserCreate = (props) => {
  const notify = useNotify();

  const onFailure = (error) => {
    notify(
      `Could not create user: ${Errors.handleError(error.message)}`,
      "error"
    );
  };

  let roles = [
    { id: USER, name: "User" },
    { id: EDITOR, name: "Editor" },
    { id: CITYADMIN, name: "City Admin" },
    { id: ADMIN, name: "Admin" },
  ];

  if (props.permissions && props.permissions.includes(SUPERADMIN)) {
    roles.push({ id: SUPERADMIN, name: "Super Admin" });
  }

  return (
    <Create title="Novo utilizador" onFailure={onFailure} {...props}>
      <SimpleForm redirect="edit">
        <ImageInput source="avatar" label="Avatar" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="username" validate={validateRequired} />
        <TextInput source="email" validate={validateEmail} />
        <PasswordInput source="password" validate={validateRequired} />
        <SelectArrayInput
          source="role"
          defaultValue={["USER"]}
          optionValue="id"
          choices={roles}
          validate={validateRequired}
        />
        <ReferenceArrayInput source="cities_ids" reference="cities">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          label="Language"
          source="language_id"
          reference="languages"
        >
          <SelectInput optionText="name" allowEmpty={false} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
