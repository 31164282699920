import React, { useState } from 'react';
import { FileInput, FileField, SimpleForm, Title, useMutation } from 'react-admin';
import { Paper, Button } from '@material-ui/core';


const BulkPoi = (props) => {
  const [json, setJson] = useState([]);
  const [service, setService] = useState(undefined);
  const [mutate] = useMutation();
  const google = window.google;
  function initMap(city) {
    let elMap = document.getElementById("map");
    let center = { lat: parseFloat(city["lat"]), lng: parseFloat(city["long"]) };
    let map = new google.maps.Map(elMap, {
      zoom: 12,
      center: center,

    });
    elMap.style.height = "400px";
    elMap.style.marginBottom = "20px";
    setService(new google.maps.places.PlacesService(map))
    new google.maps.Marker({
      position: center,
      map: map,
    });
  }

  const handleChange = async e => {
    if (e != null) {
      const fileReader = new FileReader();
      fileReader.readAsText(e, "UTF-8");
      fileReader.onload = e => {
        let jsonContent = JSON.parse(e.target.result)
        setJson(jsonContent[0]["pois"]);
        initMap(jsonContent[0]["city"]);
      }

    }
  };

  const handleImport = async (pois) => {
    for (let i = 0; i < pois.length; i++) {
      setTimeout(() => {
        let categories_ids = [pois[i]['category_id']];
        console.log(pois[0])
        let request = {
          placeId: pois[i]['id'],
          fields: ['name', 'place_id', 'rating', 'international_phone_number', 'formatted_address', 'geometry', 'photos', 'website', 'opening_hours']
        };
        service.getDetails(request, async (place, status) => {
          const schedule = {};
          if (place.opening_hours) {
            for (let i = 0; i < place.opening_hours.weekday_text.length; i++) {
              const element = place.opening_hours.weekday_text[i];
              let day = {};
              const time = element.split(": ")[1];
              if (time === "Fechado" || time === "Atendimento 24 horas") {
                if (time === "Fechado") {
                  day.open = false;
                } else {
                  day.open = true;
                }
                if (time === "Atendimento 24 horas") {
                  day.allday = true;
                } else {
                  day.allday = false;
                }
              } else {
                day.open = true;
                day.allday = false;
                const times = time.split(", ");
                day.times = times.map(t => {
                  return {
                    start_time: t.split(" – ")[0],
                    end_time: t.split(" – ")[1],
                  }
                });
              }
              switch (i) {
                case 0:
                  schedule.monday = day;
                  break;
                case 1:
                  schedule.tuesday = day;
                  break;
                case 2:
                  schedule.wednesday = day;
                  break;
                case 3:
                  schedule.thursday = day;
                  break;
                case 4:
                  schedule.friday = day;
                  break;
                case 5:
                  schedule.saturday = day;
                  break;
                case 6:
                  schedule.sunday = day;
                  break;
                default:
                  break;
              }
            }
          }
          console.log(place);
          console.log(schedule);
          insert(place, schedule, categories_ids);
        });

      }, 500 * i);
    }

  };


  const insert = async (info, event, categories_ids) => mutate({
    type: 'create',
    resource: 'pois',
    payload: {
      data: {
        title: {
          pt: info.name,
          en: info.name,
          // es: info.name,
          // fr: info.name,
        },
        published: {
          pt: true,
          en: true,
          // es: true,
          // fr: true,
        },
        rate: info.rating,
        google_gallery: info.photos ? info.photos.map(photo => { return { src: photo.getUrl({ maxWidth: 500 }) } }) : [],
        contacts: {
          phone: [info.international_phone_number],
          url: [info.website],
          email: [],
        },
        location: {
          type: "Point",
          coordinates: [info.geometry.location.lat(), info.geometry.location.lng()]
        },

        schedule: event,
        address: info.formatted_address,
        google_place_id: info.place_id,
        categories_ids: categories_ids,
      }
    },
  });

  return (
    <div>
      <Title title="Importar varios ponto de interesse" />
      <div id="map"></div>
      {(json.length > 0) ?
        <Button variant='contained' color='primary' onClick={() => handleImport(json)}>Importar {json.length} Pois</Button> : <></>}
      <SimpleForm toolbar={<></>}>
        <FileInput source="files" label="Related files" accept="application/json" onChange={handleChange} >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
      {(json.length > 0) ?
        <Paper elevation={2} style={{ marginTop: 20, padding: 20 }}>
          {json.map((place) => (
            <div key={place.id}>
              <span>
                Nome: {place.name} -
              </span>
              <span>
                - Tipo: {place.type} -
              </span>
              <span>
                - Id: {place.id}
              </span>
            </div>
          ))}
        </Paper> : <></>}
    </div>
  );
}

export default BulkPoi;